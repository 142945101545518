.landing-title-container {
  width: 69%;
  margin: 0 auto;
}

.landing-title-container-xs {
  width: 90%;
}

.landing-title-container > div {
  position: absolute;
  display: flex;
  align-items: center;
  height: 100%;
}

.landing-title > h1 {
  letter-spacing: var(--letterSpacingLg);
  font-size: var(--titleFontSize);
  margin: 0;
  line-height: max(3rem, 9vmin);
}

.landing-title > p {
  letter-spacing: var(--letterSpacingSm);
  font-size: var(--subTitleFontSize);
  margin: 0;
  margin-left: 1.25%;
  line-height: max(2rem, 5vmin);
  width: max-content;
}
