@media (prefers-color-scheme: dark) {
  :root {
    --primaryColor: #000000;
    --secondaryColor: #ffffff;
    --accentColorValues: 15, 15, 15;
    --accentColor: rgb(var(--accentColorValues));

    --primaryTextColor: rgb(248, 249, 250);
    --secondaryTextColor: rgb(33, 37, 41);
    --mutedTextColor: rgba(255, 255, 255, 0.7);

    --primaryBorderColor: var(--primaryTextColor);
    --secondaryBorderColor: var(--secondaryTextColor);

    --primaryBackgroundColor: var(--primaryTextColor);
    --secondaryBackgroundColor: var(--secondaryTextColor);

    --primaryHoverColor: var(--secondaryTextColor);
    --secondaryHoverColor: var(--primaryTextColor);

    --landingBackgroundImage: url('../images/me_dark.jpeg');
  }

  img.invertedColor,
  .navbar-light .navbar-toggler-icon {
    filter: invert(1);
  }

  .btn-close {
    filter: none;
  }

  textarea::-webkit-resizer {
    border-color: rgba(255, 255, 255, 0.1);
  }
}
