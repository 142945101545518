:root {
  color-scheme: light dark;
  --navbarHeight: 56px;

  --primaryColor: #ffffff;
  --secondaryColor: #000000;
  --accentColorValues: 248, 249, 250;
  --accentColor: rgb(var(--accentColorValues));

  --primaryTextColor: rgb(33, 37, 41);
  --secondaryTextColorValues: 248, 249, 250;
  --secondaryTextColor: rgb(var(--secondaryTextColorValues));
  --mutedTextColor: rgba(0, 0, 0, 0.7);

  --primaryBorderColor: var(--primaryTextColor);
  --secondaryBorderColor: var(--secondaryTextColor);

  --primaryBackgroundColor: var(--primaryTextColor);
  --secondaryBackgroundColor: var(--secondaryTextColor);

  --primaryHoverColor: var(--secondaryTextColor);
  --secondaryHoverColor: var(--primaryTextColor);

  --titleFontSize: max(3.5rem, 10vmin);
  --subTitleFontSize: max(1.5rem, 4vmin);
  --headingFontSize: 3vmin;

  --projectImageSize: 4rem;
  --projectImageSizeHovered: 4.5rem;

  --letterSpacingSm: 2px;
  --letterSpacingLg: 4px;

  --borderRadius: 1rem;

  --landingBackgroundImage: url('../images/me_light.jpeg');
}

html {
  scroll-padding-top: calc(56px);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Helvetica Neue', 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: var(--primaryColor) !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
div,
a {
  color: var(--primaryTextColor) !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-text-fill-color: var(--primaryTextColor);
  box-shadow: 0 0 0px 1000px var(--accentColor) inset;
  transition: background-color 1000s ease-in-out 0s;
}

input:not(input:-webkit-autofill)::-webkit-contacts-auto-fill-button {
  background-color: var(--primaryTextColor);
}

textarea::-webkit-resizer {
  border-color: rgba(0, 0, 0, 0.1);
  border-style: solid;
  border-width: 1rem 0 0 1rem;
  border-top-left-radius: 0.5rem;
  border-bottom-right-radius: 1rem;
}

.navbar-spacer {
  margin-top: var(--navbarHeight);
}
