.scroll-down-icon-container {
  position: absolute;
  right: 0;
  bottom: 1rem;
  left: 0;
  display: flex;
  justify-content: center;
}

.scroll-down-icon {
  width: 3rem;
  height: 3rem;
  border: 2px solid black;
  border-radius: 50%;
  position: relative;
  animation: down 2s infinite;
  -webkit-animation: down 2s infinite;
}

.scroll-down-icon::before {
  content: '';
  position: absolute;
  top: 0.65rem;
  left: 0.9rem;
  width: 1rem;
  height: 1rem;
  border-left: 2px solid black;
  border-bottom: 2px solid black;
  transform: rotate(-45deg);
}

@keyframes down {
  0% {
    transform: translate(0);
  }
  20% {
    transform: translateY(0.75rem);
  }
  40% {
    transform: translate(0);
  }
}

@-webkit-keyframes down {
  0% {
    transform: translate(0);
  }
  20% {
    transform: translateY(0.75rem);
  }
  40% {
    transform: translate(0);
  }
}

@media (prefers-color-scheme: dark) {
  .scroll-down-icon {
    border: 2px solid white;
  }

  .scroll-down-icon::before {
    border-left: 2px solid white;
    border-bottom: 2px solid white;
  }
}
