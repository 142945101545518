.preview-umbrella-macbook {
  width: 83%;
  height: auto;
  left: 27%;
  bottom: 0;
  z-index: 2;
}

.preview-umbrella-ipad {
  width: 40%;
  height: auto;
  right: 0;
  bottom: 1px;
  z-index: 1;
}

.preview-umbrella-iphone {
  width: 20%;
  height: auto;
  left: 43%;
  bottom: 0;
  z-index: 3;
}
