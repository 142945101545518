.landing-image {
  background-image: var(--landingBackgroundImage);
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
  position: absolute;
  top: var(--navbarHeight);
  right: 0;
  bottom: 0;
  left: 35%;
}

.landing-image-xs {
  left: 19%;
}

.landing-image-sm {
  left: 40%;
}
